import axios from '@/axios.js'

const state = () => ({
   hubungan_korbans: []
})

const mutations = {
    SET_HUBUNGANKORBANS (state, payload) {
      state.hubungan_korbans = payload
    },
    ADD_HUBUNGANKORBAN (state, item) {
      state.hubungan_korbans.unshift(item)
    },
    UPDATE_HUBUNGANKORBAN (state, hubungan_korban) {
      const hubungan_korbanIndex = state.hubungan_korbans.findIndex((p) => p.id === hubungan_korban.id)
      Object.assign(state.hubungan_korbans[hubungan_korbanIndex], hubungan_korban)
    },
    REMOVE_HUBUNGANKORBAN (state, itemId) {
      const ItemIndex = state.hubungan_korbans.findIndex((p) => p.id === itemId)
      state.hubungan_korbans.splice(ItemIndex, 1)
    },
}

const actions = {
  fetchHubunganKorban ({ commit }) {
    return new Promise((resolve, reject) => {
      axios.get(`/term-list/hubungan_korban`)
        .then((response) => {
          commit('SET_HUBUNGANKORBANS', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  addHubunganKorban ({ commit }, hubungan_korban ) {
    return new Promise((resolve, reject) => {
      axios.post('/term-add', hubungan_korban)
        .then((response) => {
          commit('ADD_HUBUNGANKORBAN', response.data.data)
          resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateHubunganKorban ({ commit }, hubungan_korban) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update/${hubungan_korban.id}`, hubungan_korban)
        .then((response) => {
            commit('UPDATE_HUBUNGANKORBAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  updateFormHubunganKorban ({ commit }, hubungan_korban) {
    return new Promise((resolve, reject) => {
      axios.post(`/term-update-form`, hubungan_korban)
        .then((response) => {
            commit('UPDATE_HUBUNGANKORBAN', response.data.data)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  },
  removeHubunganKorban ({ commit }, hubungan_korban) {
    return new Promise((resolve, reject) => {
      axios.delete(`/term-delete/${hubungan_korban.id}`)
        .then((response) => {
            commit('REMOVE_HUBUNGANKORBAN', hubungan_korban.id)
            resolve(response.data)
        })
        .catch((error) => { reject(error) })
    })
  }
}

export default {
  isRegistered: false,
  namespaced: true,
  state,
  actions,
  mutations
}